@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

@font-face {
    font-family: "supria-sans-condensed";
    src: url("./fonts/Supria-Sans-Condensed-Regular.otf") format("opentype");
  }

* {
    box-sizing: border-box;
}

html, body {
    font-family: "Open Sans", sans-serif;
    color: #26262a;
    height: 100%;
    margin: 0;
	padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto Mono', monospace;
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.box-body h1,
.box-body h2,
.box-body h3,
.box-body h4,
.box-body h5,
.box-body h6 {
    color: #26262A;
}

.box-body h1,
.box-body h2,
.box-body h3 {
    font-size: 30px;
}

.question-title, .question-prefix {
    font-family: 'Roboto Mono', monospace;
}

.main-header .logo {
    font-family: 'supria-sans-condensed', sans-serif;
}

h2.main-title {
    font-family: 'supria-sans-condensed', sans-serif;
    font-weight: normal;
}

.outer-wrapper, .main-and-foot {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 0;
    min-width: 0;
}

.content {
    flex: 1 0 auto;
    display: flex;
    width: 100%;
}

.content.sidebar-hidden {
    display: block;
}

header {
    width: 100%;
    background-color: rgb(38, 38, 42);;
    color: white;
    flex: 0 0 auto;
}

header > * {
    display: inline-block;
}

header .logo {
    font-family: 'supria-sans-condensed', sans-serif;
    height: 50px;
    width: 230px;
    text-align: center;
    line-height: 50px;
    padding: 0px 15px 0px 15px;
}

.sidebar {
    flex: 0 0 auto;
    background-color: rgba(217, 217, 214, 0.267);
    width: 230px;
    height: 100%;
}

.content.sidebar-hidden .sidebar {
    display: none;
}

.sidebar ul {
    padding-inline-start: 0px;
    margin-top: 0px;
}

.sidebar li {
    display: block;
}

.sidebar li a {
    background-color: rgb(38, 38, 42);
    color: white;
    padding: 12px 5px 12px 15px;
    display: block;
    margin: 5px;
    font-size: 14px;
    line-height: 20px;
    user-select: none;
}

.sidebar li.active a, .sidebar li a:hover {
    background-color: white;
    color: black;
    box-shadow: inset 0 0 0 3px #000;
}

.sidebar li.active a {
    cursor: default;
    pointer-events: none;
}

.sidebar li a:focus {
    box-shadow: inset 0 0 0 3px #d9d9d6;
}

footer {
    text-align: center;
    font-size: 14px;
    color: rgb(68, 68, 68);
    border-top: 1px solid rgb(210, 214, 222);
    flex: 0 0 auto;
}

.box-body {
    border: solid rgb(85, 85, 85);
    border-width: 1px 1px 1px 5px;
    padding: 1rem 2rem;
    margin: 10px auto 10px auto;
    margin-bottom: 20px;
    max-width: 1100px;
    width: 100%;
    background-color: rgba(217, 217, 214, 0.267);
    font-size: 14px;
}

header a {
    color: white;
}

header a[aria-expanded="true"]::before {
    content: "<<";
}

header a[aria-expanded="false"]::before {
    content: ">>";
}

button, input[type=button] {
    border: solid 1px #333;
    background-color: #444;
    border-radius: 0;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px 6px 12px;
}

button:focus, input[type=button]:focus {
    box-shadow: inset 0 0 0 3px #d9d9d6;
}

.btn-next, .btn-add {
    background-color: rgb(168, 222, 168);
    color: #26262a;
    border-radius: 0;
}
.btn-next:hover, .btn-add:hover {
    background-color: rgba(168, 222, 168, 0.4);
}

.btn-previous {
    background-color: rgb(249, 225, 188);
    color: #26262a;
    border-radius: 0;
}
.btn-previous:hover {
    background-color: rgba(249, 225, 188, 0.4);
}

.btn-start:hover, .btn-finish:hover, input[type=button]:hover {
    background: rgba(68, 68, 68, 0.4);
    color: black;
}

.button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 5px 10px 5px;
}

.button-row .btn-next, .button-row .btn-finish {
    margin-left: auto;
}

select {
    min-width: 200px;
}

img.block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
}

.logos {
    background-color: white;
    width: 100%;
}

.logos img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

main {
    flex: 1 0 auto;
    padding-right: 10px;
    padding-left: 10px;
}

.screen-reader-only {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.no-margin {
    margin: 0px;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.sticky {
    position: sticky;
}

.warning {
    top: 20px;
    color: white;
    background-color: black;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    text-align: right;
}
  
.warning:empty {
    padding-top: 0px;
    padding-bottom: 0px;
}

.warning a, .warning a:hover,.warning a:visited, .warning a:active {
    color: inherit;
}

.loading-indicator {
    font-weight: bold;
}

.hidden {
    display: none;
}

button:disabled, input[type=button]:disabled {
    color: #444444;
    background-color: rgba(0, 0, 0, 0.05);
    pointer-events: none;
}

.skiplink {
    font-weight: bold;
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.skiplink:focus-visible {
    height: auto;
    width: auto;
    right: 10px;
    top: 10px;
    left: auto;
}

main:focus-visible {
    box-shadow: inset 0 0 20px #222200;
    border: 1px solid black;
}


@media screen and (max-width: 800px) {
    .content {
        display: block;
    }
    .sidebar {
        height: auto;
        width: 100%;
        text-align: center;
    }
    .sidebar li a {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    .sidebar ul {
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 450px) {
    .skiplink:focus-visible {
        position: static;
        display: block;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: max-content;
        max-width: 100%;
    }
}